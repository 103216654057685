import * as React from "react";
import {Component} from "react";
// @ts-ignore
import qidigoFetch from "qidigo-fetch";
// @ts-ignore
import {navigate} from "qidigo-router";
// @ts-ignore
import Loading from 'qidigo-components/loading';
import {
    IBillingControllerProps,
    IBillingControllerState,
    ICoupon,
    IOrder,
    IPendingOrderResponseException
} from "../../../views/billing/types";
import qidigoAuth from "../../../modules/qidigo-auth";
// @ts-ignore
import {connect} from "@app/lib/redux";
// @ts-ignore
import {removeItem, fetchCart} from "@app/store/actions/cart";
//@ts-ignore
import PropTypes from "prop-types";
//@ts-ignore
import Logger from "qidigo-logger";
import PendingOrderView from "../../../views/billing/pendingOrder/pendingOrderView";
import QidigoBackLocation from "../../../modules/qidigo-router/back_location";

export interface IPendingOrderControllerState extends IBillingControllerState {
    order: IOrder,
    itemIsBeingDeleted: boolean,
    applyingCouponError: boolean,
    isCouponLimitExceeded: boolean,
    needReload: boolean,
}

const ADJUSTMENT_LIMIT_CODE = 'ADJUSTMENT_REDEMPTION_LIMIT_EXCEEDED';
const PAYMENT_METHOD_IS_INVALID = 'PAYMENT_METHOD_IS_INVALID';
const INSTALLMENT_IS_INVALID = 'INSTALLMENT_IS_INVALID';

class PendingOrderController extends Component<IBillingControllerProps, IPendingOrderControllerState> {
    async componentDidMount() {
        const slug = this.props.organization.slug
        if (await qidigoAuth.userLoggedIn() === false) {
            QidigoBackLocation.saveLastLocation();
            navigate('/login');
            return;
        }

        let hasContactError = false
        const contact = await qidigoFetch
            .get(`order/customers?organization=${slug}`)
            .catch(() => {
                hasContactError = true
            });

        if (hasContactError) {
            this.goBack();

            return;
        }

        await qidigoFetch
            .get(`order/customers/${contact.id}/pending-order/forms`)
            .then((formList) => {
                const areFormsCompleted = formList.reduce((isAllComplete, {is_complete}) => isAllComplete && is_complete, true);

                if (!areFormsCompleted) {
                    navigate('/u/' + slug + '/pending-order/forms')
                }
            });
        const userId = qidigoAuth.getUserID();
        let hasOrderError = false
        let order = await qidigoFetch
            .get(`order/customers/${contact.id}/pending-order`)
            .catch((e) => {
                hasOrderError = true;
            })
        ;

        if (hasOrderError) {
            this.goBack();

            return;
        }

        if (order.lines.length === 0) {
            this.goBack();

            return;
        }

        const installmentOptionList = await qidigoFetch.get(
            `order/customers/${contact.id}/pending-order/installments?total=${order.price_detail.total}`
        );
        const sortedInstallmentOptionList = [...installmentOptionList].sort((installmentOptionA, installmentOptionB) => installmentOptionA.order - installmentOptionB.order);
        const paymentMethods = await qidigoFetch.get(`order/customers/${contact.id}/pending-order/payment-options`)
        const contactCredit = await qidigoFetch.get(`customers/${contact.id}/credit`);
        const contactAddressList = await qidigoFetch.get(`users/${userId}/addresses`);

        this.setState({
            contactUuid: contact.id,
            contactSubscribedToMailingList: contact.is_subscribed_to_mailing_list,
            order: order,
            contactCredit: contactCredit,
            paymentMethods: paymentMethods,
            installmentList: sortedInstallmentOptionList,
            addressList: contactAddressList,
            isFree: order.price_detail.total === 0,
            creditApplied: 0,
            applyingCouponError: false,
            isCouponLimitExceeded: false,
            processingPayment: false,
            shouldSaveMailingList: true,
            contactMailingId: contact.id_for_mail_subscription,
            redirectionTimer: 5,
            selectedInstallmentId: null,
            selectedPaymentMethodId: null,
            itemIsBeingDeleted: false,
            needReload: false,
        })
    }

    reload = async (couponCode: string | null, showCouponApplication: boolean = false) => {
        this.setState({
            needReload: false
        });

        const orderQuery = couponCode ? `?coupon=${couponCode}` : "";
        let isAdjustmentLimitExceeded = false;
        const order: IOrder = await qidigoFetch.get(`order/customers/${this.state.contactUuid}/pending-order${orderQuery}`)
            .catch((exception: IPendingOrderResponseException) => {
                if (exception.error_code === ADJUSTMENT_LIMIT_CODE) {
                    isAdjustmentLimitExceeded = true;

                    return this.state.order;
                }
            })
        ;

        if (order.lines.length === 0) {
            this.setState({
                order: order,
                paymentMethods: [],
                installmentList: [],
                itemIsBeingDeleted: false
            });
            return;
        }

        if (showCouponApplication && couponCode) {
            this.setState({
                isCouponLimitExceeded: isAdjustmentLimitExceeded,
                applyingCouponError: !isAdjustmentLimitExceeded && this.checkDisplayCouponApplicationError(order),
            })
        }

        let paymentError;
        const paymentMethodsPromise = qidigoFetch.get(`order/customers/${this.state.contactUuid}/pending-order/payment-options`).catch((error) => paymentError = error);
        const installmentOptionListPromise = qidigoFetch.get(`order/customers/${this.state.contactUuid}/pending-order/installments?total=${order.price_detail.total}`);
        let paymentMethods = await paymentMethodsPromise;
        const installmentOptionList = await installmentOptionListPromise;

        if (order.price_detail.total < 0.5) {
            paymentMethods = paymentMethods.filter((paymentMethod) => paymentMethod.type !== 'STRIPE');
        }

        if (paymentError) {
            this.setState({
                order: order,
                paymentMethods: [],
                installmentList: [],
                itemIsBeingDeleted: false
            });

            return
        }

        this.setState({
            order: order,
            paymentMethods: paymentMethods,
            installmentList: installmentOptionList,
            isFree: (order.price_detail.total - this.state.creditApplied) === 0,
            itemIsBeingDeleted: false
        });
    }

    handleItemIsBeingDeleted = (isBeingDeleted: boolean) => {
        this.setState({
            itemIsBeingDeleted: isBeingDeleted
        })
    }

    checkDisplayCouponApplicationError = (order: IOrder): boolean => {
        if (order.applied_coupon === null) {
            return true;
        }

        const appliedCouponId = order.applied_coupon.adjustment_id;
        for (let index = 0; index < order.price_detail.adjustments.length; index++) {
            const adjustment = order.price_detail.adjustments[index];
            if (adjustment.id === appliedCouponId) {
                return false;
            }
        }

        return true;
    }

    removeLine = (cartId) => {
        //@ts-ignore
        const {dispatch} = this.props;
        return dispatch(removeItem(cartId));
    }

    reloadInstallment = async (total: number) => {
        const installmentList = await qidigoFetch.get(`order/customers/${this.state.contactUuid}/pending-order/installments?total=${total}`);
        this.setState({
            installmentList: installmentList,
        });
    }

    reloadPaymentMethods = async () => {
        const { contactUuid } = this.state;
        const paymentMethods = await qidigoFetch.get(`order/customers/${contactUuid}/pending-order/payment-options`)
        this.setState({
            paymentMethods: paymentMethods,
        });
    }


  handleSaveMailingListCheckboxChange = (isChecked: boolean) => {
        this.setState({
            shouldSaveMailingList: isChecked
        })
    }

    handleTokenBadRequest = (code: string) => {
        this.setState({
            paymentErrors: code,
            processingPayment: false
        });
    }

    handlePayment = (
        credit: number,
        version: number,
        paymentMethodId: number | null,
        token: string | null,
        installmentId: string | null,
        couponCode: ICoupon | null,
    ) => {
        this.setState({
            processingPayment: true
        })
        //@ts-ignore
        const {dispatch} = this.props;

        const url = `contacts/${this.state.contactUuid}/pending-order/settlements`;
        const body = {
            'payment_method_id': paymentMethodId,
            'token': token,
            'installment_id': installmentId,
            'credit': credit,
            'coupon_code': couponCode ? couponCode.code : null,
            'order_version': version,
        }

        const init = {
            'method': 'POST',
            'body': JSON.stringify(body),
            'headers': {
                'Qidigo-Organization-Id': this.props.organization.future_id
            }
        };

        qidigoFetch.fetch(url, init)
            .then(response => {
                if (response.status === 422) {
                    return response.json()
                        .then((body: IPendingOrderResponseException) => {
                            const code: string = body.error_code === 'INVALID_PARAMETERS'
                            && body['invalid-params']['order_version'] !== undefined
                                ? 'INVALID_VERSION'
                                : body.error_code;

                            let order = Object.assign({}, this.state.order);
                            order.version = code !== 'INVALID_VERSION'
                            && body.does_payment_passed === false
                                ? this.state.order.version + 1
                                : this.state.order.version;

                            this.setState({
                                paymentErrors: code,
                                processingPayment: false,
                                order: order,
                                selectedInstallmentId: installmentId,
                                selectedPaymentMethodId: paymentMethodId
                            })

                            if (code === PAYMENT_METHOD_IS_INVALID) {
                                this.reloadPaymentMethods();

                                return;
                            }

                            if (code === INSTALLMENT_IS_INVALID) {
                                const balance = this.state.order.price_detail.total - this.state.creditApplied;
                                this.reloadInstallment(balance);

                                return;
                            }

                            if (code === ADJUSTMENT_LIMIT_CODE) {
                                this.setState({
                                    needReload: true,
                                    isCouponLimitExceeded: true,
                                    applyingCouponError: false,
                                });
                            }

                            if (body.error_code === 'CART_IS_EMPTY') {
                                this.setState({
                                    processingPayment: true
                                })
                                window.scrollTo(0, 0);
                                dispatch(fetchCart());
                                const interval = setInterval(() => this.updateRedirectionTimer(interval), 1000)
                                return;
                            }
                        });
                }
                if (!response.ok) {
                    return response.json()
                        .then(() => {
                            Logger.catcher;
                            let order = Object.assign({}, this.state.order);
                            this.setState({
                                paymentErrors: 'UNEXPECTED_ERROR',
                                processingPayment: false,
                                order: order
                            })
                        });
                }

                if (!this.state.contactSubscribedToMailingList
                    && this.state.shouldSaveMailingList) {
                    qidigoFetch.post(`users/${this.state.contactMailingId}/email_subscriptions`,
                        {id: this.props.organization.id});
                }

                return response.json().then((data) => {
                    dispatch(fetchCart());
                    const from = data['with_payment'] ? 'pending-order' : 'pending-order-free';
                    const slug = window.location.pathname.split('/', 3)[2];

                    window.location.href = `/u/${slug}/invoices/${data['invoice_id']}/show?from=${from}`;
                });
            });
    }

    updateRedirectionTimer(interval) {
        if (this.state.redirectionTimer <= 1) {
            clearInterval(interval)
            this.goBack()
        }

        this.setState({
            redirectionTimer: this.state.redirectionTimer - 1
        })
    }

    goBack = () => {
        const slug = window.location.pathname.split('/', 3)[2];
        navigate("/u/" + slug);
    }

    applyCredit = async (creditApplied: number) => {
        const balance = this.state.order.price_detail.total - creditApplied;
        await this.reloadInstallment(balance);
        this.setState({
            isFree: balance === 0,
            creditApplied: creditApplied
        });
    }

    render() {
        if (!this.state) {
            return <Loading/>;
        }

        if (this.state.needReload) {
            this.reload(null, false);

            return <Loading/>;
        }

        return (
            <PendingOrderView
                order={this.state.order}
                contactCredit={this.state.contactCredit}
                paymentMethods={this.state.paymentMethods}
                installmentList={this.state.installmentList}
                addressList={this.state.addressList}
                reload={this.reload}
                removeLine={this.removeLine}
                handlePayment={this.handlePayment}
                handleTokenBadRequest={this.handleTokenBadRequest}
                isFree={this.state.isFree}
                applyCredit={this.applyCredit.bind(this)}
                hasCouponErrorOnApplication={this.state.applyingCouponError}
                hasCouponLimitErrorOnApplication={this.state.isCouponLimitExceeded}
                paymentErrors={this.state.paymentErrors}
                processingPayment={this.state.processingPayment}
                organizationName={this.props.organization.name}
                organizationUuid={this.props.organization.future_id}
                itemIsBeingDeleted={this.state.itemIsBeingDeleted}
                handleItemIsBeingDeleted={this.handleItemIsBeingDeleted.bind(this)}
                contactIsSubscribedToMailingList={this.state.contactSubscribedToMailingList}
                mailingListCheckBoxValue={this.state.shouldSaveMailingList}
                handleMailingListCheckBox={this.handleSaveMailingListCheckboxChange.bind(this)}
                redirectionTimer={this.state.redirectionTimer}
                selectedInstallmentId={this.state.selectedInstallmentId}
                selectedPaymentMethodId={this.state.selectedPaymentMethodId}
            />
        )
    }
}

//@ts-ignore
PendingOrderController.propTypes = {
    dispatch: PropTypes.func,
};

export default connect()(PendingOrderController);
